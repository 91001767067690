<template>
  <el-card id="practical">
    <header>
      <h1>查询实际续费金额</h1>
    </header>
    <el-divider></el-divider>
    <main>
      <!-- echarts -->
      <div id="chartColumn" style="height: 72vh; width: 100%"></div>
    </main>
  </el-card>
</template>

<script>
import * as echarts from "echarts";
import { companyRenewFindOperatorsRenew } from "@/api/company_renew";
export default {
  data() {
    return {
      chartColumn: null,
      r_money: null,
      s_money: null,
      y_money: null,
      operators: null,
      renew_count: null,
      count: null,
      not_count: null,
    };
  },
  created() {
    this.getList();
  },
  methods: {
    // echarts
    drawColumnChart() {
    //   console.log("标题", this.titleArr);
    //   console.log("值", this.valueArr);
      this.chartColumn = echarts.init(document.getElementById("chartColumn"));
      this.chartColumn.setOption({
        title: { text: "续费金额排名" },
        tooltip: {},
        legend: {
          orient: "vertical",
          left: "right",
          data: [
            "实续费金额",
            "次月扣费",
            "预计总续费金额",
            "已处理续费公司个数",
            "公司总数(待续费+已处理公司个数)",
            "待续费公司个数",
          ],
        },
        xAxis: {
          //   interval: 0, //去除间隙  刻度间隔为0
          data: this.titleArr,
          //   data: [12, 232, 123, 123, 12, 3, 123, 1, 23, 123, 1, 231],
        },
        yAxis: {
          // data: this.valueArr,
        },
        axisLabel: {
          interval: 0,
          formatter: function (value) {
            // debugger;
            var ret = ""; //拼接加\n返回的类目项
            var maxLength = 4; //每项显示文字个数
            var valLength = value.length; //X轴类目项的文字个数
            var rowN = Math.ceil(valLength / maxLength); //类目项需要换行的行数
            if (rowN > 1) {
              //如果类目项的文字大于3,
              for (var i = 0; i < rowN; i++) {
                var temp = ""; //每次截取的字符串
                var start = i * maxLength; //开始截取的位置
                var end = start + maxLength; //结束截取的位置
                //这里也可以加一个是否是最后一行的判断，但是不加也没有影响，那就不加吧
                temp = value.substring(start, end) + "\n";
                ret += temp; //凭借最终的字符串
              }
              return ret;
            } else {
              return value;
            }
          },
        },
        series: [
          {
            name: "实续费金额",
            type: "bar",
            data: this.valueArr,
          },
          {
            name: "次月扣费",
            type: "bar",
            data: this.valueArra,
          },
          {
            name: "预计总续费金额",
            type: "bar",
            data: this.valueArrb,
          },
          {
            name: "已处理续费公司个数",
            type: "bar",
            data: this.valueArrc,
          },
          {
            name: "公司总数(待续费+已处理公司个数)",
            type: "bar",
            data: this.valueArrd,
          },
          {
            name: "待续费公司个数",
            type: "bar",
            data: this.valueArre,
          },
        ],
      });
    },
    // 获取数据
    getList() {
      let params = {};
      let res = companyRenewFindOperatorsRenew(params);
      res.then((res) => {
        let data = res.data.data;
        let s_money = 0;
        let r_money = 0;
        let y_money = 0;
        let renew_count = 0;
        let count = 0;
        let not_count = 0;
        data.forEach((v, i) => {
          s_money += v.s_money;
          r_money += v.r_money;
          y_money += v.y_money;
          renew_count += v.renew_count;
          count += v.count;
          not_count += v.not_count;
        });
        this.s_money = s_money;
        this.r_money = r_money;
        this.y_money = y_money;
        this.renew_count = renew_count;
        this.count = count;
        this.not_count = not_count;
        this.titleArr = [];
        this.valueArr = [];
        this.valueArra = [];
        this.valueArrb = [];
        this.valueArrc = [];
        this.valueArrd = [];
        this.valueArre = [];
        let newArr = data.map((v) => {
          return { title: v.operators, value: v.r_money };
        });
        let newArra = data.map((v) => {
          return { title: v.operators, value: v.s_money };
        });
        let newArrb = data.map((v) => {
          return { title: v.operators, value: v.y_money };
        });
        let newArrc = data.map((v) => {
          return { title: v.operators, value: v.renew_count };
        });
        let newArrd = data.map((v) => {
          return { title: v.operators, value: v.count };
        });
        let newArre = data.map((v) => {
          return { title: v.operators, value: v.not_count };
        });
        // newArr.sort(function (a, b) {
        //   return b.value - a.value;
        // });
        // newArra.sort(function (a, b) {
        //   return b.value - a.value;
        // });
        newArr.forEach((v) => {
          this.titleArr.push(v.title);
          this.valueArr.push(v.value);
        });
        newArra.forEach((v) => {
          this.valueArra.push(v.value);
        });
        newArrb.forEach((v) => {
          this.valueArrb.push(v.value);
        });
        newArrc.forEach((v) => {
          this.valueArrc.push(v.value);
        });
        newArrd.forEach((v) => {
          this.valueArrd.push(v.value);
        });
        newArre.forEach((v) => {
          this.valueArre.push(v.value);
        });
        this.$nextTick(() => {
          this.drawColumnChart();
        });
      });
    },
  },
};
</script>

<style lang="less" scoped>
#practical {
  header {
    h1 {
      font-weight: normal;
    }
  }
}
</style>