/**
 * company_renew
 * 
 */

import request from '@/utils/request'

// 
export const companyRenewAddWithRenew = (data) => {
    return request({
        method: 'post',
        url: '/company_renew/add-withRenew',
        data
    })
}
// 
export const companyRenewFindNoRenew = (data) => {
    return request({
        method: 'post',
        url: '/company_renew/find-noRenew',
        data
    })
}
// 
export const companyRenewFindOperatorsRenew = (data) => {
    return request({
        method: 'post',
        url: '/company_renew/find-operatorsRenew',
        data
    })
}
